<template functional>
  <section class="app-main">
    <el-main>
      <transition
          mode="out-in"
          name="fade"
      >
        <router-view />
      </transition>
    </el-main>
  </section>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'AppMain',
  computed: {
    ...mapGetters(['currentProjectId']),
  },
  watch: {
    currentProjectId() {
      const trackerId = this.$route.params.idTracker;
      this.$store.commit('filters/SET_ISSUE_FILTER_VALUE', {
        idTracker: trackerId,
        filter: 'projects.id',
        value: [],
      });
      this.$store.commit('filters/SET_ISSUE_FILTER_TOOGLE', {
        filter: 'projects.id',
        value: false,
        idTracker: trackerId,
      });
    },
  },
};
</script>
<style scoped>
.app-main {
  /*84 = navbar + tags-view = 50 +34 */
  min-height: calc(100vh - 60px);
  position: relative;
  overflow: hidden;
  background-color: #F2F4F9;
  background-image: url('../../../static/bg.jpg');
  background-repeat: repeat;
}
</style>
